import React, { Component } from 'react';
import Header from '../Header';
import './Thanks.css';
import Footer from '../Footer';

export class Thanks extends Component {
  render() {
    return (
      <div>
        <Header />
        <div className='thanks'>
            <h1 className='title'>Thank You!</h1>
            <h3 className='message'>Your message has been delivered!</h3>
            <p><a className='link' href='/'>Go back.</a></p>
        </div>
        <Footer />
      </div>
    )
  }
}

export default Thanks