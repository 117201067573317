import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Thanks from './pages/Thanks';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />
  },
  {
    path: "/thanks",
    element: <Thanks />
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  
    <RouterProvider router={router} />
  
);
