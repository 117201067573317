import React, { Component } from 'react';
import './Footer.css';

export class Footer extends Component {
  render() {
    return (
      <div className='footer d-flex justify-content-around'>
        <h3>Velocità <br/>Garage</h3>
        <p>12345 Street name, <br/>City/ON A0A 0B0 <br/>(416) 000-0000</p>
      </div>
    )
  }
}

export default Footer