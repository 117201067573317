import './App.css';
import './Contact.css';
import Contact from './Contact';
import Header from './Header';
import Footer from './Footer';

function App() {
  return (
    <div className="App">
      <Header />
      <div className='body'>

        <div className='Main light'>
          <h2 className='MainSlogan smallscreen'>Precision. <br/>Performance. <br/>Passion.</h2>
          <img className='MainImg smallscreen' src='./images/main.png' alt='sport car'/>
        </div>

        <div data-bs-spy="scroll" data-bs-target="#navbar-example2" data-bs-root-margin="0px 0px -40%" data-bs-smooth-scroll="true"
        class="scrollspy-example rounded-2" tabindex="0">

          <div className='elements dark' id="ourMission">
            <img className='image smallscreen' src='./images/engine.jpg' alt='engine' />
            <div className='content smallscreen'>
              <h4 className='title'>Our mission</h4>
              <p>At Velocità  Garage, we believe that car maintenance doesn't need to be complicated. Our goal is to make it easy for everyone to keep 
                their cars in top shape. Let us take care of your car today!
              </p>
            </div>          
          </div>

          <div className='elements ourservices light' id="ourServices">          
            <div className='content smallscreen'>
              <h4 className='title'>Our Services</h4>
              <p>From simple repairs to complex modifications, we've got you covered. Our expert mechanics are here to provide the best
              service possible. Come visit us today!
              </p>            
            </div>
            <img className='image smallscreen' src='./images/brake.jpg' alt='brakes' />
          </div> 

          <div className='elements dark' id="aboutUs">
            <img className='image smallscreen' src='./images/girlflag.jpg' alt='girl holding canadian flag' />
            <div className='content smallscreen'>
              <h4 className='title'>About us</h4>
              <p>Velocità Garage was founded with the mission to provide high quality car services to our costumers. With years of experience, 
                our team of expert mechanics are dedicated to ensuring custumer satisfaction.
              </p>
            </div>
          </div>

          <Contact />
        </div>
      </div>
      
      <Footer />
    </div>
  );
}

export default App;
