import React, { Component } from 'react'
import './Contact.css';


export class Contact extends Component {
  render() {
    return (
        <div className='contact' id="contact">
            <h4 className='contactTitle'>Contact</h4>
            <form action="https://formsubmit.co/ee9c781b0f27870ecc2b606aa5cf1adb" method="POST">
                <input type="hidden" name="_captcha" value="false" />
                <input type="hidden" name="_next" value="https://project1.guireis.net/thanks" />

                <div className="mb-3">
                    <label for="name" className="form-label">Name*</label>
                    <input type="text" className="form-control" name="name" placeholder="Please, insert your name here!" required />
                </div>
                <div className="mb-3">
                    <label for="email" className="form-label">Email*</label>
                    <input type="email" className="form-control" name="email" placeholder="name@example.com" required />
                </div>
                <div className="mb-3">
                    <label for="phone" className="form-label">Phone</label>
                    <input type="tel" className="form-control" name="phone" placeholder="(xxx) xxx-xxxx" />
                </div>
                <div className="mb-3">
                    <label for="content" className="form-label">Message*</label>
                    <textarea className="form-control" name="content" rows="5" placeholder="Type your message here" required></textarea>
                </div>
                <button  type="submit" className="btn btn-secondary">Send</button>
            </form>
        </div>
    )
  }
}

export default Contact