import React, { Component } from 'react';
import './Header.css';

export class Header extends Component {
  render() {
    return (
      <div className='header'>
        <nav id="navbar-example2" class="navbar px-3 mb-3">
          <a className="navbar-brand" href="/">Velocità Garage</a>
          <ul className="nav nav-pills">
            <li className="nav-item">
              <a className="nav-link" href="../#ourMission">Our mission</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="../#ourServices">Our services</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="../#aboutUs">About us</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="../#contact">Contact</a>
            </li>
          </ul>
      </nav>
      </div>
    )
  }
}

export default Header